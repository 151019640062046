"use client";

import { useEffect } from "react";
import Navbar from "@/components/navbar/Navbar";
import { useUser } from "@/contexts/user";
import Footer from "@/components/shared/Footer";
import { useQuery } from "@apollo/client";
import { MyBasicInfoDocument
// MyUsernameDocument,
} from "@/graphql/generated/types";
import Image from "next/image";
export default function GlobalError({
  error
}:
// reset,
{
  error: Error;
  // reset: () => void
}) {
  const {
    user: loggedInUser
  } = useUser();
  const currentYear = new Date().getFullYear();
  let user = null;
  const {
    data: myData
  } = useQuery(MyBasicInfoDocument, {
    skip: !loggedInUser
  });
  if (myData?.me?.username) {
    user = myData?.me;
  }
  useEffect(() => {
    console.error("Global server error caught:", error);
  }, [error]);
  return <>
      <Navbar isLoggedIn={!!user} user={user} data-sentry-element="Navbar" data-sentry-source-file="error.tsx" />

      <div className="text-white px-5 flex max-w-[575px] mx-auto items-center flex-col justify-center min-h-[calc(100vh-184px)]            ">
        <Image src="/assets/error.webp" alt="500 error" width={175} height={154} data-sentry-element="Image" data-sentry-source-file="error.tsx" />
        <h2 className="text-2xl text-center sm:leading-10 sm:text-[28px] mt-4 font-semibold">
          500 Internal Server Error
        </h2>
        <p className="text-base text-[#e0e0e0] leading-7 text-center mt-2.5 font-medium">
          We are working to resolve the issue. Please try again later.
        </p>
      </div>
      <Footer currentYear={currentYear} data-sentry-element="Footer" data-sentry-source-file="error.tsx" />
    </>;
}